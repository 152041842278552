<!--商品列表 ALL-->
<template>
  <div class="page-good-list">

    <Breadcrumb
      :data="[
        {
          name:tagName
        }
      ]"
    ></Breadcrumb>

    <div class="common-gap"></div>

    <div class="bottom-content">

      <el-row :gutter="30" class="good-list">
        <el-col class="mb40" :key="item.id" :span="6" v-for="item in goodList">
          <GoodCard :item="item"></GoodCard>
        </el-col>
      </el-row>

      <el-empty v-if="goodList.length===0" description="No Data"></el-empty>

      <div style="text-align: center">
        <el-pagination
          @current-change="pageChange"
          @size-change="sizeChange"
          :current-page="pageNum"
          :page-size="pageSize"
          :page-sizes="[8,20,40]"
          layout="prev, pager, next,sizes"
          :total="total">
        </el-pagination>
      </div>


    </div>

  </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb'
import GoodCard from '@/components/GoodCard'

import {
  getAllProductClaasify,
  queryProductByClassify,
  queryProductByTag,
  queryTagDetail,
  showAllFunction
} from '@/api/good'

export default {
  components: { Breadcrumb, GoodCard },
  data() {
    return {
      tag: -1,
      pageNum: 1,
      pageSize: 8,
      total: 0,
      goodList: [],
      tagName:'Tag'
    }
  },
  methods: {
    async pageChange(page) {
      this.pageNum = page
      await this.queryData()
    },
    async sizeChange(pageSize) {
      this.pageSize = pageSize
      this.pageNum = 1
      await this.queryData()
    },
    async queryData() {
      const res = await queryProductByTag(
        {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          tag: this.tag,
        }
      )
      console.log('res', res)
      this.goodList = res.data.list
      this.total = res.data.total
    },
    async queryName(){
      const res = await  queryTagDetail((this.$route.params.id));
      this.tagName = res.data.tagName
    }
  },
  created() {
    console.log('检测到参数:', this.$route)
    this.tag = Number(this.$route.params.id)
    this.queryName()
    this.queryData()

  },
  watch: {
    $route(){
      this.tag = Number(this.$route.params.id)
      this.queryName()
      this.queryData()
    },
  }
}
</script>

<style lang="less" scoped>

.prod-group {
  margin-bottom: 20px;
}

.mb40 {
  margin-bottom: 40px;
}

.bottom-content {
  background: var(--main-color-bg-gray);
  padding: 36px 75px 75px;
}
</style>
